<template>
  <a-form :form="form">
    <a-form-item label="指标" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
      <a-input-search
        v-decorator="['metricsConfig.cnName',{  rules: [{ required: true, message: '指标不能为空' }] }]"
        placeholder="请选择指标"
        @search="handleMetrics">
        <a-button type="primary" slot="enterButton">
          <a-icon type="plus"/>
        </a-button>
      </a-input-search>
<!--      <a-button size="small" type="primary" icon="plus" style="margin-left: 10px;" @click="handleMetrics">-->
<!--        选择指标-->
<!--      </a-button>-->
    </a-form-item>
    <a-form-item label="所属网关" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
      <a-input-search
        v-decorator="['gateway.cnName',{rules: [{ required: true, message: '网关不能为空' }] }]"
        placeholder="请选择网关"
        @search="handleGateway">
        <a-button type="primary" slot="enterButton">
          <a-icon type="plus"/>
        </a-button>
      </a-input-search>
<!--      <a-button size="small" type="primary" icon="plus" style="margin-left: 10px;" @click="handleGateway">-->
<!--        选择网关-->
<!--      </a-button>-->
    </a-form-item>
    <a-modal title="指标选择列表" :width="1200" :footer="null" :visible="metricsModal" @cancel="closeModal">
      <MetricsList @metricsSelect="metricsSelect"></MetricsList>
    </a-modal>
    <a-modal title="指标网关列表" :width="1200" :footer="null" :visible="gatewayModal" @cancel="closeModal">
      <Gateway @gatewaySelect="gatewaySelect"></Gateway>
    </a-modal>
  </a-form>
</template>

<script>
  import configVO from './common/configVO'
  import { metricsType } from './common/common'
  import SERVICE_URLS from '@/api/service.url'
  import entityCURDCallback from '../../../../../common/mixins/entityCURDCallback'
  import MetricsList from './MetricsList'
  import Gateway from './Gateway'

  export default {
    name: 'QuestionForm',
    components: { MetricsList, Gateway },
    mixins: [entityCURDCallback],
    props: {
      deviceId: {
        type: Number
      },
      categoryId: {
        type: [Number, String]
      },
      sup_this: {
        type: Object
      },
      gateWayList: {
        type: Array,
        required: true
      },
      configList: {
        type: Array,
        required: true
      }
    },
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.equipment2.metricsRealInfoApi,
        initFormItem: configVO(),
        // form: this.$form.createForm(this),
        showFormItem: false,
        metricsType: metricsType(),
        id: '',
        questionLabelCol: { span: 5 },
        questionWrapperCol: { span: 19 },
        metricsConfig: {},
        escape: { zero: '', one: '' },
        metricsModal: false,
        gatewayModal: false,
      }
    },
    methods: {
      // 把form取到的值 赋给创建的formItem
      setVOFields (values) {
        this.formItem.device.id = this.deviceId
      },
      // 给form赋值
      setFields (values) {
        Object.assign(this.formItem, values)
      },
      closeModal () {
        this.metricsModal = false
        this.gatewayModal = false
      },
      handleMetrics () {
        this.metricsModal = true
      },
      handleGateway () {
        this.gatewayModal = true
      },
      selectMetricsType (value) {
        this.formItem.metricsConfig.metricsType = value
      },
      metricsSelect (data) {
        this.formItem.metricsConfig = data
        this.form.setFieldsValue({
          'metricsConfig.cnName': this.formItem.metricsConfig.cnName
        })
        this.metricsModal = false
      },
      gatewaySelect (data) {
        console.log(data)
        this.formItem.gateway = data
        this.form.setFieldsValue({
          'gateway.cnName': this.formItem.gateway.cnName
        })
        this.gatewayModal = false
      },
      addSuccessCallback () {
        this.formItem = {
          id: '',
          device: {
            id: ''
          },
          gateway: {
            id: ''
          },
          metricsConfig: {
            id: ''
          }
        }
      }
    }
  }
</script>

<style scoped>
  .dynamic-delete-button {
    cursor: pointer;
    position: relative;
    top: 4px;
    font-size: 24px;
    color: #999;
    transition: all 0.3s;
  }

  .dynamic-delete-button:hover {
    color: #777;
  }

  .dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
</style>
