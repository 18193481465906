export const equipmentConfigColums = () => {
  return [
    {
      title: '序号',
      width: '60px',
      scopedSlots: { customRender: '_index' },
      align: 'center',
    },
    {
      title: '指标中文名称',
      dataIndex: 'metricsConfig.cnName',
      align: 'center',
    },
    {
      title: '指标英文名称',
      dataIndex: 'metricsConfig.enName',
      align: 'center',
    },
    {
      title: '指标编码',
      dataIndex: 'metricsConfig.code',
      align: 'center',
    },
    {
      title: '指标类型',
      dataIndex: 'metricsConfig.metricsType',
      scopedSlots: { customRender: 'metricsConfig.metricsType' },
      align: 'center',
    },
    {
      title: '转义',
      dataIndex: 'metricsConfig.escapeContent',
      scopedSlots: { customRender: 'metricsConfig.escapeContent' },
      align: 'center',
    },
    {
      title: '指标单位',
      dataIndex: 'metricsConfig.unit',
      scopedSlots: { customRender: 'metricsConfig.unit' },
      align: 'center',
    },
    {
      title: '指标最大值',
      width: '90px',
      dataIndex: 'metricsConfig.maxValue',
      scopedSlots: { customRender: 'metricsConfig.maxValue' },
      align: 'center',
    },
    {
      title: '指标最小值',
      width: '80px',
      dataIndex: 'metricsConfig.minValue',
      scopedSlots: { customRender: 'metricsConfig.minValue' },
      align: 'center',
    },
    {
      title: '所属网关',
      dataIndex: 'gateway.cnName',
      align: 'center',
    },
    {
      title: '核心指标',
      width: '60px',
      dataIndex: 'metricsConfig.core',
      align: 'center',
      scopedSlots: { customRender: 'core' },
    },
    {
      title: '操作',
      width: '130px',
      key: 'action',
      scopedSlots: { customRender: 'action' },
      align: 'center',
    },
  ]
}
export const configColums = () => {
  return [
    {
      title: '序号',
      width: 60,
      scopedSlots: { customRender: '_index' },
      align: 'center',
    },
    {
      title: '指标中文名称',
      dataIndex: 'cnName',
      align: 'center',
    },
    {
      title: '指标英文名称',
      dataIndex: 'enName',
      align: 'center',
    },
    {
      title: '指标标识名',
      dataIndex: 'distinguishedName',
      align: 'center',
    },
    {
      title: '操作',
      key: 'action',
      scopedSlots: { customRender: 'action' },
      align: 'center',
    },
  ]
}
export const gatewayColumns = () => {
  return [
    {
      title: '序号',
      width: '80px',
      align: 'center',
      scopedSlots: { customRender: '_index' },
    },
    {
      title: '厂家类型',
      align: 'center',
      dataIndex: 'type',
      width: '250px',
      scopedSlots: { customRender: 'typeSlot' },
    },
    {
      title: '网关编号',
      align: 'center',
      width: '150px',
      dataIndex: 'deviceGatewayNo',
      scopedSlots: { customRender: 'deviceGatewayNo' },
    },
    {
      title: '中文名称',
      align: 'center',
      width: '200px',
      dataIndex: 'cnName',
    },
    {
      title: '安装地点',
      align: 'left',
      dataIndex: 'installLocation',
    },
    {
      title: '操作',
      align: 'center',
      scopedSlots: { customRender: 'action' },
    },
  ]
}
export const metricsType = () => {
  return [
    { value: 'yc', name: '遥测' },
    { value: 'yx', name: '遥信' },
    { value: 'yt', name: '遥调' },
    { value: 'yk', name: '遥控' },
  ]
}
export const companyTypeList = () => {
  return [
    { value: 'jk', label: 'GRM云服务器(巨控)' },
    { value: 'lyyjkj', label: '洛阳愿景科技有限公司' },
    { value: 'tspsdzjs', label: '唐山平升电子技术开发有限公司' },
  ]
}
