const RKFormVO = () => {
  return {
    id: '',
    department: {
      id: ''
    },
    depot: {
      id: ''
    },
    user: {
      id: ''
    },
    deviceCondition: '',
    reason: '', //出库原因
    time: '',
    orgId: 0,
    type: 'RK'
  }
}

export default RKFormVO