<template>
  <a-form :form="form">
    <a-form-item  label="所属部门"
                  :label-col="questionLabelCol"
                  :wrapper-col="questionWrapperCol">
      <a-tree-select placeholder="请选择所属部门" @change="loadDepotsList" :treeData="deptList"  v-decorator="['deptId',{rules: [{ required: true, message: '请选择部门' }]}]">
      </a-tree-select>
    </a-form-item>
    <a-form-item  label="存放仓库"
                  :label-col="questionLabelCol"
                  :wrapper-col="questionWrapperCol">
      <a-select placeholder="请选择仓库" v-decorator="['depotId',{rules: [{ required: true, message: '请选择仓库' }]}]">
        <a-select-option v-for="item in depotsList" :key="item.id" :value="item.id"  >{{item.name}}</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item label="入库日期" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
      <a-date-picker style="width: 100%"
                     v-decorator="['time',{initialValue:moment(new Date(),'YYYY-MM-DD'),rules: [{ required: true, message: '请选择时间' }]}]"
                     placeholder="请选择日期"></a-date-picker>
    </a-form-item>
    <a-form-item  label="入库人"
                  :label-col="questionLabelCol"
                  :wrapper-col="questionWrapperCol">
      <a-select placeholder="请选择入库人"  disabled v-decorator="['userId',{rules: [{ required: true, message: '请选择巡检人' }],trigger:'change'}]">
        <a-select-option v-for="item in userList" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item  label="设备状态"
                  :label-col="questionLabelCol"
                  :wrapper-col="questionWrapperCol">
      <a-radio-group :options="deviceConditionList" v-decorator="['deviceConditionId',{initialValue:'WELL'}]" />
    </a-form-item>
    <a-form-item label="入库原因" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
      <a-input  v-decorator="['reason']" placeholder="请输入入库原因"></a-input>
    </a-form-item>
  </a-form>

</template>

<script>
  import RKForm from '../common/RK-formVO'
  import SERVICE_URLS from '@/api/service.url'
  import { deviceCondition } from '../common/common'
  import moment from 'moment'
  export default {
    props: {
    },
    name: 'testForm',
    data () {
      return {
        form: this.$form.createForm(this),
        questionLabelCol: { span: 5 },
        questionWrapperCol: { span: 17 },
        formItem: RKForm(),
        userList: [],
        deptList: [],
        id: '',
        deviceConditionList: deviceCondition(),
        depotsList: []
      }
    },
    created () {
    },
    methods: {
      moment,
      loadFunction() {
        this.loadCheckUserList()
        this.loadDept()
        this.loadUser()
        this.loadDepotsList(this.currentDeptId().organizationId.toString())
      },
      loadDepotsList (value) {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.equipmentInfoApi.selectDepots,
          noTips: true,
          data: {
            departmentId: value
          },
          success: (data) => {
            this.depotsList = data.body
          }
        })
      },
      loadUser() {
        this.form.setFieldsValue({
          userId: this.$store.getters.currentUser.id
        })
      },
      loadDept() {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.deptList = data.body
          }
        })
        this.form.setFieldsValue({
          deptId: this.currentDeptId().organizationId + ''
        })
      },
      loadCheckUserList () {
        this.$http(this, {
          url: SERVICE_URLS.user.userOrgJob.orgUser,
          noTips: true,
          success: (data) => {
            this.userList = data.body
          }
        })
      },
      setVOFields(values) {
        this.formItem = {
          department: {
            id: values.deptId
          },
          depot: {
            id: values.depotId
          },
          user: {
            id: values.userId
          },
          location: values.location,
          latitude: values.latitude,
          longitude: values.longitude,
          reason: values.reason, //出库原因
          time: values.time,
          deviceCondition: values.deviceConditionId,
          orgId: 0,
          type: 'RK'
        }
      },
      handleAddSubmit() {
        this.form.validateFields((err, values) => {
          if (!err) {
            this.setVOFields(values)
            this.$http(this, {
              url: SERVICE_URLS.equipment2.equipmentInfoApi.transfer,
              data: this.formItem,
              noTips: true,
              params: {
                id: this.id
              },
              success: (data) => {
                console.log('保存成功')
                this.$emit('RKSucceed', 'RKSucceed')
              }
            })
            this.form.resetFields()
          }
        })
      },
      getEquipmentInfo(id) {
        this.loadFunction()
        this.$http(this, {
          url: SERVICE_URLS.equipment2.equipmentInfoApi.view,
          noTips: true,
          params: {
            id: id
          },
          success: (data) => {
            this.id = id
          }
        })
      }
    }
  }
</script>

<style scoped>

</style>