export const onLineColums = () => {
  return [
    {
      title: '序号',
      width: 50,
      fixed: 'left',
      scopedSlots: { customRender: '_index' },
      align: 'center',
    },
    {
      title: '设备名称',
      dataIndex: 'name',
      width: 200,
      key: 'name',
      ellipsis: true,
      scopedSlots: { customRender: 'name' },
      align: 'center',
    },
    {
      title: '设备编号',
      dataIndex: 'no',
      key: 'no',
      ellipsis: true,
      align: 'center',
    },
    {
      title: '设备型号',
      dataIndex: 'model',
      key: 'model',
      width: 150,
      align: 'center',
      ellipsis: true,
      scopedSlots: { customRender: 'model' },
    },
    {
      title: '类型',
      dataIndex: 'transferType',
      width: 100,
      ellipsis: true,
      scopedSlots: { customRender: 'transferType' },
      align: 'center',
    },
    {
      title: '设备所属部门',
      dataIndex: 'department.name',
      align: 'left',
    },
    {
      title: '所属设施',
      dataIndex: 'facility.name',
      align: 'left',
    },
    {
      title: '安装位置',
      dataIndex: 'detailLocation',
      width: 200,
      ellipsis: true,
      align: 'left',
      scopedSlots: { customRender: 'detailLocation' },
    },
    {
      title: '操作',
      key: 'action',
      fixed: 'right',
      width: 300,
      scopedSlots: { customRender: 'action' },
      align: 'center',
    },
  ]
}
export const deviceCondition = () => {
  return [
    { value: 'WELL', label: '良好' },
    { value: 'DAMAGE', label: '损坏' },
  ]
}
