const ZYFormVO = () => {
  return {
    id: '',
    area: {
      id: ''
    },
    department: {
      id: ''
    },
    user: {
      id: ''
    },
    location: '',
    reason: '', //出库原因
    time: '',
    orgId: 0,
    type: 'ZY'
  }
}

export default ZYFormVO