<template>
      <qiniu-image-upload
        ref="qiniuImageRef" @getImageData="getImageData"
        :document-dir-id="3"
        :multiSelection="true"
        type="edit"
        :items="images"
      ></qiniu-image-upload>
</template>

<script>
  import QiniuImageUpload from '@/components/QiniuUpload/QiniuImageUpload'
  import SERVICE_URLS from '@/api/service.url'
  export default {
    components: { QiniuImageUpload },
    props: {
      sup_this: {
        type: Object,
        required: true
      }
    },
    name: 'localImageForm',
    data () {
      return {
        images: [],
        formItem: {
          imageIds: []
        },
      }
    },
    methods: {
      /*上传图片回调方法*/
      getImageData (data) {
        data.forEach(item => {
          this.formItem.imageIds.push(item.id)
        })
      },
      setVOFields(values) {
        this.formItem = {
        }
      },
      handleAddSubmit() {
          this.$http(this, {
            url: SERVICE_URLS.equipment2.equipmentInfoApi.sceneImage,
            data: this.formItem.imageIds,
            params: {
              id: this.id
            },
            noTips: true,
            success: (data) => {
              console.log('保存成功')
              this.sup_this.search()
            }
          })
      },
      getEquipmentInfo(id) {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.equipmentInfoApi.view,
          params: {
            id: id
          },
          noTips: true,
          success: (data) => {
            this.id = id
          }
        })
      },
    }
  }
</script>

<style scoped>

</style>